@tailwind base;
@tailwind components;
@tailwind utilities;

@font-face {
  font-family: 'netto_otregular';
  src: url('./assets/fonts/netto_ot-webfont.woff2') format('woff2'),
       url('./assets/fonts/netto_ot-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;

}
@media only screen and (min-width: 0px) {
  .box{
  transform: translate3d(0px, 10%, 0px);

  }

  .videotItem {
    margin-bottom: 10px;
}

}



@media only screen and (min-width: 600px) {
  .box{
  transform: translate3d(0px, 20%, 0px);

  }


}

@media only screen and (min-width: 640px) {

  .videotItem {
    margin-bottom: 0px;
}

}


body {
  margin: 0;
  font-family: 'netto_otregular';
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

ol,ul, menu{      list-style: disc;
  margin: 10px;
  padding: 0px 0px;
}
/*  color */

.color1{background: -webkit-linear-gradient(left,rgba(22,126,123,1) 0,rgba(37,183,140,1) 100%);}
.color2{ height:40px;background-color:#1A7833 ;}
.color3{ height:40px;background-color:#C7C604 ;}
.color4{ height:40px;background-color:#6DB52C ;}
.color5{ height:40px;background-color:#8DB524 ;}



/*  end color */
.embed-container {
  position: relative;
  padding-bottom: 56.25%;
  height: 0;
  overflow: hidden;
  max-width: 100%;
}

.embed-container iframe,
.embed-container object,
.embed-container embed {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


/*  logo 

.logowrapper{
  width: 100%;
  margin: 0 auto;
  background: url(./assets/image/hero-4.jpeg) no-repeat;
  background-size: cover;
  background-position: 80% 12%;
  height: 800px;}

*/

.shape::after{    content: '';
  position: absolute;
  left: 0;
  top: -12px;
  width: 100%;
  height: 100%;
  background: gray;
  border-bottom-right-radius: 75px;
}

.flower{height:400px;}

.flower:after{    content: '';
  position: absolute;
  left: 0;
  top: 0px;
  width: 100%;
  height: 100%;
  background: url(./assets/image/kasia.svg) 0 0 no-repeat;

}


.shapeshake:after {
  content: '';
  transform: skewY(-3deg);
  -webkit-transform: skewY(-3deg);
}


.logowrapper{width:100%;  margin:0 auto; }


.containerinfo{position: relative;
  margin-left: auto;
  margin-right: auto;
  width: 100%;
  max-width: 1280px;
  padding: 20px 0;}

  

  .contactItem{  
    color: #fff;
    font-size: 20px;
    position: relative;
    text-decoration: none;}


/*  end logo */

/*  video */

.videowrapper, .rowx{width:100%}


/*  end video */


.google-map-code iframe {width:100%; height:450px;}


/*  email form 

.container {
  font-family: 'netto_otregular';
  max-width: 400px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}




#contact input[type="text"],
#contact input[type="email"],
#contact input[type="tel"],
#contact input[type="url"],
#contact textarea,
#contact button[type="submit"] {
  font: 400 12px/16px "Roboto", Helvetica, Arial, sans-serif;
}

#contact {
  background: #F9F9F9;
  padding: 25px;
  margin: 150px 0;
  box-shadow: 0 0 20px 0 rgba(0, 0, 0, 0.2), 0 5px 5px 0 rgba(0, 0, 0, 0.24);
}

#contact h3 {
  display: block;
  font-size: 30px;
  font-weight: 300;
  margin-bottom: 10px;
}

#contact h4 {
  margin: 5px 0 15px;
  display: block;
  font-size: 13px;
  font-weight: 400;
}

fieldset {
  border: medium none !important;
  margin: 0 0 10px;
  min-width: 100%;
  padding: 0;
  width: 100%;
}

#contact input[type="text"],
#contact input[type="email"],
#contact input[type="tel"],
#contact input[type="url"],
#contact textarea {
  width: 100%;
  border: 1px solid #ccc;
  background: #FFF;
  margin: 0 0 5px;
  padding: 10px;
}

#contact input[type="text"]:hover,
#contact input[type="email"]:hover,
#contact input[type="tel"]:hover,
#contact input[type="url"]:hover,
#contact textarea:hover {
  -webkit-transition: border-color 0.3s ease-in-out;
  -moz-transition: border-color 0.3s ease-in-out;
  transition: border-color 0.3s ease-in-out;
  border: 1px solid #aaa;
}

#contact textarea {
  height: 100px;
  max-width: 100%;
  resize: none;
}

#contact button[type="submit"] {
  cursor: pointer;
  width: 100%;
  border: none;
  background: #4CAF50;
  color: #FFF;
  margin: 0 0 5px;
  padding: 10px;
  font-size: 15px;
}

#contact button[type="submit"]:hover {
  background: #43A047;
  -webkit-transition: background 0.3s ease-in-out;
  -moz-transition: background 0.3s ease-in-out;
  transition: background-color 0.3s ease-in-out;
}

#contact button[type="submit"]:active {
  box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.5);
}

.copyright {
  text-align: center;
}

#contact input:focus,
#contact textarea:focus {
  outline: 0;
  border: 1px solid #aaa;
}

::-webkit-input-placeholder {
  color: #888;
}

:-moz-placeholder {
  color: #888;
}

::-moz-placeholder {
  color: #888;
}

:-ms-input-placeholder {
  color: #888;
}


  end email form */
  input, textarea{padding-left:10px;padding-top: 5px;
    padding-bottom: 5px;}
  textarea:focus, input:focus{
    outline: 0;
    outline: none;
    border:none;
    background-color:transparent;
}


  .contactItem{padding-left: 28px;}
  #Capa_1{stroke:"white"; fill:"white";}

  .contactItem__phone:after {
    content: "";
    background: url(./assets/image/phone.svg) no-repeat;
    background-size: contain;
    width: 20px;
    height: 20px;
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
}

.collection{padding:40px 0px;}
.videotItem{  
  color: #fff;
  font-size: 15px;
  position: relative;
  text-decoration: none;
  padding-left: 45px;}

.contactItem__video:after {
  content: "";
  background: url(./assets/image/video.svg) no-repeat;
  background-size: contain;
  width: 30px;
  height: 30px;
  position: absolute;
  left: 10px;
  top: 0;
  bottom: 0;
  margin: auto;
}



.contactItem__mail:after {
  content: "";
  background: url(./assets/image/mail.svg) no-repeat;
  background-size: contain;
  width: 21px;
  height: 21px;
  position: absolute;
  left: 0;
  top: 0;
  bottom: 0;
  margin: auto;
}

button:disabled,
button[disabled]{
opacity:0.5;
}


.mailme{margin-left: 15px;}

.grecaptcha-badge { 
  visibility: hidden;
}

label[for="messagex"], #messagex {
  display: none;
  visibility: hidden;
}

/*  begin intersection obserever*/

.box{

  opacity: 0.01;
  transition: opacity 0.7s ease-in-out,
-webkit-transform 0.7s ease-in-out;
  transition: transform 0.7s ease-in-out,
opacity 0.7s ease-in-out;
  transition: transform 0.7s ease-in-out,
opacity 0.7s ease-in-out,
-webkit-transform 0.7s ease-in-out;
}

.mystyles{

  opacity: 0.01;
transform: translateY(-220px);
  transition: opacity 1s ease-in-out,
-webkit-transform 1s ease-in-out;
  transition: transform 1s ease-in-out,
opacity 1s ease-in-out;
  transition: transform 1s ease-in-out,
opacity 1s ease-in-out,
-webkit-transform 1s ease-in-out;
}
.actives{  width: 100%;
opacity: 1;
  -webkit-transform: translate3d(0px, 0px, 0px);
  transform:  translate3d(0px, 0px, 0px); 

}

/* eind intersection obserever*/

 .containerhero, .containervimeo, .containercontact{background: -webkit-linear-gradient(left,rgba(37,183,140,1) 30%,rgba(22,126,123,1) 100%);}

 .grecaptcha-badge{right:0px !important;}

 /* footer delay*/

 .end {

  animation: 3s fadeIn;
  animation-fill-mode: forwards;
  
  visibility: hidden;
}



@keyframes fadeIn {
  99% {
    visibility: hidden;
  }
  100% {
    visibility: visible;
  }
}


.pop {
  animation: fadeInAnimation ease 1s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@keyframes fadeInAnimation {
 from {
      opacity: 0;
      transform:  translate3d(0, 235px, 0)
  }
  to {
      opacity: 1;
      transform: translate3d( 0, 0, 0)
  }
}